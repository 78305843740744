import Vue from "vue";
import Vuex from "vuex";
//BOC
import apparel from "./modules/apparel";
import auth from "./modules/auth";
import pick from "./modules/pick";
import skin from "./modules/skin";
import tutorial from "./modules/tutorial";
import settings from "./modules/settings";
import event from "./modules/event";
import boss from "./modules/boss";
import series from "./modules/series";
import coin from "./modules/coin";
import gameCache from "./modules/gameCache";
import miniGame from "./modules/miniGame";
import abc from "./modules/abc";
//EOC

Vue.use(Vuex);
const debug = process.env.VUE_APP_ENV !== "production";

//BOC:[persist]
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
const vuexCookie = new VuexPersistence({
  key: "cookieStore",
  restoreState: (key) => Cookies.get(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 30, // expire in 30 days
    }),
  modules: [
    //
  ],
});
const vuexLocal = new VuexPersistence({
  key: "localStore",
  storage: window.localStorage,
  modules: [
    "apparel",
    "auth",
    "pick",
    "skin",
    "tutorial",
    "settings",
    "event",
    "series",
    "boss",
    "coin",
    "gameCache",
    "miniGame",
    "abc",
  ],
});
//EOC

export default new Vuex.Store({
  modules: {
    apparel: apparel,
    auth: auth,
    pick: pick,
    skin: skin,
    tutorial: tutorial,
    settings: settings,
    event: event,
    series: series,
    boss: boss,
    coin: coin,
    gameCache: gameCache,
    miniGame: miniGame,
    abc: abc
  },
  strict: debug,
  plugins: [vuexCookie.plugin, vuexLocal.plugin],
});
